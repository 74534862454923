<template>
  <a-spin :spinning="confirmLoading">
    <a-collapse v-model="activeKey">

<!--      <a-collapse-panel :forceRender="true" key="1" header="其他信息">-->
<!--        <a-form :form="form1">-->
<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label="首次分享">-->
<!--            <a-input-number :step="1" :min="0" v-decorator="['firstShare', {}]" placeholder="请输入"></a-input-number>-->
<!--          </a-form-item>-->


<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label="注册赠送金币">-->
<!--            <a-input-number :step="1" :min="0" v-decorator="['firstLogin', {}]" placeholder="请输入"></a-input-number>-->
<!--          </a-form-item>-->

<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label="邀请用户赠送金币数量">-->
<!--            <a-input-number :step="1" :min="0" v-decorator="['invitation-money', {}]" placeholder="请输入"></a-input-number>-->
<!--          </a-form-item>-->

<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label="观看视频赠送金币">-->
<!--            <a-input-number :step="1" :min="0" v-decorator="['lookVideo', {}]" placeholder="请输入"></a-input-number>-->
<!--          </a-form-item>-->

<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label="开启推送赠送金币">-->
<!--            <a-input-number :step="1" :min="0" v-decorator="['openPush', {}]" placeholder="请输入"></a-input-number>-->
<!--          </a-form-item>-->

<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label="剧场展示状态">-->
<!--            <a-select v-decorator="['isShowTheater', {}]" placeholder="请选择">-->
<!--              <a-select-option value="0">关闭</a-select-option>-->
<!--              <a-select-option value="1">开启</a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->

<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label="H5分享地址">-->
<!--            <a-input v-decorator="['h5Url', {}]" placeholder="请输入H5分享地址"/>-->
<!--          </a-form-item>-->

<!--          <a-form-item-->
<!--            :labelCol="labelCol"-->
<!--            :wrapperCol="wrapperCol"-->
<!--            label=" "-->
<!--            :colon="false">-->
<!--            <a-button type="primary" @click="handleOk1">-->
<!--              保存-->
<!--            </a-button>-->
<!--          </a-form-item>-->
<!--        </a-form>-->
<!--      </a-collapse-panel>-->


      <a-collapse-panel :forceRender="true" key="2" header="福利规则">
        <a-form :form="form1">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="福利规则">
<!--            <a-input-number :step="1" :min="0" v-decorator="['firstShare', {}]" placeholder="请输入"></a-input-number>-->
              <editor v-model="welfareRule" id="welfareRule"></editor>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label=" "
            :colon="false">
            <a-button type="primary" @click="handleOk2">
              保存
            </a-button>
          </a-form-item>
        </a-form>
      </a-collapse-panel>

      <a-collapse-panel :forceRender="true" key="3" header="许可证">
        <a-form :form="form1">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="许可证">
            <!--            <a-input-number :step="1" :min="0" v-decorator="['firstShare', {}]" placeholder="请输入"></a-input-number>-->
            <editor v-model="xvjezheng" id="xvjezheng"></editor>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label=" "
            :colon="false">
            <a-button type="primary" @click="handleOk3">
              保存
            </a-button>
          </a-form-item>
        </a-form>
      </a-collapse-panel>
    </a-collapse>
  </a-spin>
</template>

<script>
import {queryByConfigGroup, updateByConfigGroup} from '@/api/system/config'
import pick from 'lodash.pick'
import ossUpload from '@/utils/upload-file-to-oss.js'
import Editor from "@/components/Editor/Editor";

export default {
  name: "otherSetting",
  components: {
    Editor
  },
  data() {
    return {
      description: '其他设置',
      activeKey: ['1'],
      labelCol: {
        xs: {span: 24},
        sm: {span: 5},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 10},
      },
      confirmLoading: false,
      uploadLoadinghome:false,
      form1: this.$form.createForm(this),
      form2: this.$form.createForm(this),
      form3:this.$form.createForm(this),
      form4:this.$form.createForm(this),
      form5:this.$form.createForm(this),
      model1: {},
      model2: {},
      model3: {},
      model4: {},
      model5:{},
      validatorRules1: {
        logo: {rules: [{required: true, message: '请上传logo'}]},
        platformName: {rules: [{required: true, message: '请输入平台名称'}]},
        province: {rules: [{required: true, message: '请输入省'}]},
        city: {rules: [{required: true, message: '请输入市'}]},
        area: {rules: [{required: true, message: '请输入区/县'}]},
        address: {rules: [{required: true, message: '请输入详细地址'}]},
      },
      uploadLoading:false,
      uploadLoadingthree:false,
      uploadLoadingfour:false,
      uploadLoadinggroup:false,
      welfareRule:'',
      xvjezheng:''
    }
  },
  created() {
    this.initModel1();
    this.initModel2();
    this.initModel3();
    // this.initModel4();
    // this.initModel5();
  },
  methods: {
    handleUpload(info) { //上传主图(一张图)
      let that = this;
      this.model1.logo = "";
      this.uploadLoading = true;
      ossUpload.ossUpload(info.file, function (fileName) {
        that.uploadLoading = false;
        that.model1.logo = fileName;
        console.log('---------------')
        console.log(fileName)
        that.$nextTick(() => {
          that.form1.setFieldsValue({
              'logo':fileName
            })
          })

      })
    },
    handleUpload6(info) {
      let that = this;
      this.model1.groupUrl = "";
      this.uploadLoadinggroup = true;
      ossUpload.ossUpload(info.file, function (fileName) {
        that.uploadLoadinggroup = false;
        that.model1.groupUrl = fileName;
        console.log('---------------')
        console.log(fileName)
        that.$nextTick(() => {
          that.form1.setFieldsValue({
            'groupUrl':fileName
          })
        })

      })
    },
    handleUpload1(info) {
      let that = this;
      this.model1.homeLogo = "";
      this.uploadLoadinghome = true;
      ossUpload.ossUpload(info.file, function (fileName) {
        that.uploadLoadinghome = false;
        that.model1.homeLogo = fileName;
        console.log('---------------')
        console.log(fileName)
        that.$nextTick(() => {
          that.form1.setFieldsValue({
            'homeLogo':fileName
          })
        })

      })
    },
    handleUpload3(info) {
      let that = this;
      this.model4.three = "";
      this.uploadLoadingthree = true;
      ossUpload.ossUpload(info.file, function (fileName) {
        that.uploadLoadingthree = false;
        that.model4.three = fileName;
        console.log('---------------')
        console.log(fileName)
        that.$nextTick(() => {
          that.form4.setFieldsValue({
            'three':fileName
          })
        })

      })
    },
    initModel1() {
        this.form1.resetFields();
      queryByConfigGroup({"configGroup": "app"}).then(res => {
        if (res.success) {
          this.model1 = Object.assign({}, res.data);
          this.$nextTick(() => {
            this.form1.setFieldsValue(pick(this.model1, 'isShowTheater','firstShare','firstLogin','invitation-money','lookVideo','openPush','h5Url'))
          });
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    initModel2() {
      this.form2.resetFields();
      queryByConfigGroup({"configGroup": "welfareRule"}).then(res => {
        if (res.success) {
          this.model2 = Object.assign({}, res.data);
          this.welfareRule = res.data.welfareRule;
          this.$nextTick(() => {
            this.form2.setFieldsValue(pick(this.model2, 'welfareRule'))
          });
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    initModel3() {
      this.form3.resetFields();
      queryByConfigGroup({"configGroup": "xvjezheng"}).then(res => {
        if (res.success) {
          this.model3 = Object.assign({}, res.data);
          this.xvjezheng = res.data.xvjezheng;

          this.$nextTick(() => {
            this.form3.setFieldsValue(pick(this.model3, 'xvjezheng'))
          });
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    // initModel4() {
    //   this.form4.resetFields();
    //   getAction(this.url.queryByConfigGroup, {"configGroup": "openingRemarks"}).then(res => {
    //     if (res.success) {
    //       this.model4 = Object.assign({}, res.result);
    //       this.$nextTick(() => {
    //         this.form4.setFieldsValue(pick(this.model4, 'one', 'two','three','four'))
    //       });
    //     } else {
    //       this.$message.warning(res.message)
    //     }
    //   })
    // },
    // initModel5() {
    //   this.form5.resetFields();
    //   getAction(this.url.queryByConfigGroup, {"configGroup": "withdrawal"}).then(res => {
    //     if (res.success) {
    //       this.model5 = Object.assign({}, res.result);
    //       this.$nextTick(() => {
    //         this.form5.setFieldsValue(pick(this.model5, 'min', 'poundage'))
    //       });
    //     } else {
    //       this.$message.warning(res.message)
    //     }
    //   })
    // },
    handleOk1() {
      const that = this;
      // 触发表单验证
      this.form1.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = Object.assign(this.model1, values);
          that.save("app", formData)
        }
      })
    },
    handleOk2() {
      const that = this;
      // 触发表单验证
      this.form2.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            welfareRule:that.welfareRule
          };
          that.save("welfareRule", formData)
        }
      })
    },
    handleOk3() {
      const that = this;
      // 触发表单验证
      this.form3.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            xvjezheng:that.xvjezheng
          };
          that.save("xvjezheng", formData)
        }
      })
    },
    handleOk4() {
      const that = this;
      // 触发表单验证
      this.form4.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = Object.assign(this.model4, values);
          that.save("openingRemarks", formData)
        }
      })
    },
    handleOk5() {
      const that = this;
      // 触发表单验证
      this.form5.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = Object.assign(this.model5, values);
          that.save("withdrawal", formData)
        }
      })
    },
    save(configGroup, formData) {
      var that = this;
      var configList = [];
      for (var key in formData) {
        if(key == 'logo') {
          configList.push({
            "configKey": key,
            "configValue": this.model1.logo
          })
        } else if(key == 'homeLogo') {
          configList.push({
            "configKey": key,
            "configValue": this.model1.homeLogo
          })
        } else {
          configList.push({
            "configKey": key,
            "configValue": formData[key]
          })
        }

      }

      var data = {
        "configGroup": configGroup,
        "configList": configList
      };
      updateByConfigGroup(data).then((res) => {
        if (res.success) {
          that.$message.success(res.message);
        } else {
          that.$message.warning(res.message);
        }
      }).finally(() => {
        this.confirmLoading = false;
      })
    }
  }
}
</script>
<style scoped>

textarea {
  resize: none;
}
</style>
